<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-banner>
            星戟<br> 产品特点
        </service-support-banner>
        <div class="container-1646">
          <div class="flex-between-wrap Solutions-space">
            <div  class="Solutions" v-for="(item,index) in Solutions" :key="index">
              <img src="../assets/images/service-support/bulb.png" class="Solutions-img"/>
              <div  class="Solutions-title">{{item.title}}</div>
              <div class="Solutions-specialty">{{  item.specialty}}</div>
              <div   class="Solutions-description">
               {{ item.description }}
              </div>
            </div>
          </div>
          
        </div>
        <div>
          <map-company></map-company>
        </div>   
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import MapCompany from '@/components/map'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner,
      MapCompany
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        Solutions: [
          {
            title: "解决途径一",
            description: '超融合模块式机房，采用全闪存架构，降低70%的能耗，同时采用智能制冷技术，大大降低能耗，满足数据中心的PUE需求。 ',
          },
          {
            title: "解决途径二",
            description:'超融合模块根据行业需求和使用 场景预先内置各种软硬件设施， 方便用户使用，真正满足各类 用户“开箱即用”的快速需求。 ',
          },
          {
            title: "解决途径三",
            description:'超融合模块机房产品是一个移动的微型数据中心，不限场地、 灵活部署，能够满足恶劣环境下的 社区物联网以及边缘计算需求。 ',
          },
          {
            title: "解决途径四",
            description: '采用自研的智能运维平台，通过集中采集各类数据，分析数据预估趋势，对设备进行自动化、智能化的全局管理，从而提升运维效率，保障安全稳定运行。 ',
          },
          {
            title: "解决途径五",
            description: '空间占用节省50%，重量 减轻70%；可折叠模块结构， 再节省30%的空间。 ',
            specialty:'全闪存单元'
          },
            {
                title: "解决途径六",
                description: '模块自带云端一体化端点检测和 相应平台，保障终端运行环境安全， 到达顶级等保要求数据不丢失、复用不等待，50年无需转存 ',
                specialty:'EDR安全技术 '
            },
            {
                title: "解决途径七",
                description: '超融合IDC模块可兼容传统数据中心产品，模块化配套设施与 传统IT机柜无缝兼容，即插即用。',
                specialty:'兼容传统产品 '
            },
            {
                title: "解决途径八",
                description: '重构告警链路实现了秒级敏态感知，应用图计算、物模型等技术实现了告警极速收敛，故障自动定位的准确率高',
                specialty:'智能运维软件控制，全自动远程维护 '
          },
        ],
      
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
    return {
        ...toRefs(useData),
      goTo,
      go
    }
  }
}
  </script>
  <style scoped>
.Solutions{
  width: 394px;
height: 390px;
background: #FFFFFF;
margin: 20px 0px;
box-shadow: 6px 0px 38px 2px rgb(249,247,255);
}
.Solutions-space{
  margin: 120px 0px;
}
.Solutions-title{
  font-size: 28px;text-align: center;padding: 0 0 30px;
}
.Solutions-description{
  font-size: 18px;
  color: #999999;
  padding: 0px 52px 10px;
  position: relative;
  text-align: center;
}
.Solutions-img{
  width: 47px;
  height: 50px;
  margin: 20px auto 18px;display: block;
}
.Solutions-specialty{
  text-align: center;
  font-size: 20px;
  color: #53B578;
}
</style>
  